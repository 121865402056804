import React, { memo, useState } from 'react';
import { Button, Heading1, Icon, Input } from '@roc-digital/ui-web';
import { InputData, mxmIcon, useChangeEvent, useClickEvent, useCustomEvent } from '@roc-digital/ui-lib';
import { Event } from '@roc-digital/mxm-base/dist/types';
import { SessionState } from '@roc-digital/mxm-base/state';
import { signinWithFirebase } from '@/logic';
import SocialSignin from '@/components/views/Login/SocialSignin';

const Login = memo(() => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleEmailChange = useChangeEvent(
    ({ data }: Event<InputData>) => {
      setEmail(data.value as string);
    },
    {},
    [],
    [email]
  );

  const handlePasswordChange = useChangeEvent(
    ({ data }: Event<InputData>) => {
      setPassword(data.value as string);
    },
    {},
    [],
    [password]
  );

  const handleLoginClick = useClickEvent(
    () => {
      setLoading(true);
      signinWithFirebase(SessionState, email, password);
    },
    {},
    [],
    [email, password]
  );

  useCustomEvent(
    'signin.failed',
    () => {
      setLoading(false);
      alert('Failed to sign in');
    },
    { namespace: 'mxm.auth' },
    {},
    []
  );

  return (
    <div className="flex flex-col items-center justify-center h-screen w-full">
      <div className="mb-12 flex flex-col justify-center items-center text-center align-center text-3xl text-admin-dark">
        <Icon className={'mb-4'} src={mxmIcon} size={'large'} />
        <Heading1 className="text-3xl text-admin-dark">Sign in</Heading1>
      </div>
      <div className="mb-4 w-80">
        <label className="block mb-2 text-sm font-medium text-gray-700">Email Address</label>
        <Input
          type="email"
          value={email}
          changeEvent={handleEmailChange}
          className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div className="mb-4 w-80">
        <label className="block mb-2 text-sm font-medium text-gray-700">Password</label>
        <Input
          type="password"
          value={password}
          changeEvent={handlePasswordChange}
          className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <Button clickEvent={handleLoginClick} loading={loading}>
        Sign in
      </Button>
      <div className={'w-full p-5 my-6'}>
        <SocialSignin></SocialSignin>
      </div>
    </div>
  );
});

Login.displayName = 'Login';

export default Login;
