import React, { memo, useEffect } from 'react';
import { formatDistance, format } from 'date-fns';
import { listArticles, listArticlesByCategory } from '@roc-digital/mxm-base/logic';
import { Article, Articles } from '@roc-digital/mxm-base/data';
import { Heading1, Chip, ArticleView, Spacer, Input, Pressable, HorizontalArticleView } from '@roc-digital/ui-web';
import {
  politicsIcon,
  financeMoneyIcon,
  followersIcon,
  internationalIcon,
  policyIcon,
  americaFirstIcon,
  lifeCultureIcon,
  addFillIcon,
  useStateEvent,
  useCustomEvent,
  PublisherData,
  useClickEvent,
} from '@roc-digital/ui-lib';
import { Event } from '@roc-digital/mxm-base/types';

const Content = memo(() => {
  const [allArticles] = useStateEvent<Articles>('mxm.http.articles', 'listArticles.success', undefined);
  const [categoryArticles] = useStateEvent<Articles>('mxm.http.articles', 'listArticlesByCategory.success', undefined);
  const currentDate = new Date();
  const [filters, setFilters] = React.useState<string[]>([]);

  useCustomEvent(
    'signin.success',
    () => {
      void listArticles(1, 12);
    },
    { namespace: 'mxm.auth' }
  );

  useEffect(() => {
    if (filters.length >= 0) {
      void listArticlesByCategory(filters.join(','), 1, 12);
    }
  }, [filters]);

  const handleFilterClick = useClickEvent(
    ({ data }: Event<PublisherData>) => {
      if (data.id === 'all') {
        setFilters([]);
        return;
      }
      if (filters.includes(data.id as string)) {
        setFilters(filters.filter((filter) => filter !== data.id));
      } else {
        setFilters([...filters, data.id as string]);
      }
    },
    {},
    {},
    [filters]
  );

  return (
    <div className={'h-full flex flex-col justify-center bg-gray-100 max-w-7xl mx-auto'}>
      <div className={'flex flex-row justify-between'}>
        <Heading1 className={'mt-6'}> {format(new Date(), 'MMMM d')}</Heading1>
        <Input className={'w-2/12 py-2 border mt-6 mb-5'} placeholder={'Search'}></Input>
      </div>
      <div className={'flex flex-row mb-3 justify-center'}>
        <Pressable clickEvent={handleFilterClick} id={'all'}>
          <Chip className={'mr-2 !text-black text-sm'} title={'All'} icon={false} />
        </Pressable>
        <Pressable clickEvent={handleFilterClick} id={'politics'}>
          <Chip className={'mr-2 !text-black text-sm'} title={'Politics'} icon={true} src={politicsIcon} />
        </Pressable>
        <Pressable clickEvent={handleFilterClick} id={'finance'}>
          <Chip className={'mr-2 !text-black text-sm'} title={'Finance and Money'} icon={true} src={financeMoneyIcon} />
        </Pressable>
        <Pressable clickEvent={handleFilterClick} id={'followers'}>
          <Chip className={'mr-2 !text-black text-sm'} title={'Following'} icon={true} src={followersIcon} />
        </Pressable>
        <Pressable clickEvent={handleFilterClick} id={'international'}>
          <Chip className={'mr-2 !text-black text-sm'} title={'International'} icon={true} src={internationalIcon} />
        </Pressable>
        <Pressable clickEvent={handleFilterClick} id={'policy'}>
          <Chip className={'mr-2 !text-black text-sm'} title={'Policy'} icon={true} src={policyIcon} />
        </Pressable>
        <Pressable clickEvent={handleFilterClick} id={'america'}>
          <Chip className={'mr-2 !text-black text-sm'} title={'America first'} icon={true} src={americaFirstIcon} />
        </Pressable>
        <Pressable clickEvent={handleFilterClick} id={'life'}>
          <Chip className={'mr-2 !text-black text-sm'} title={'Life and culture'} icon={true} src={lifeCultureIcon} />
        </Pressable>
        <Pressable clickEvent={handleFilterClick} id={'all'}>
          <Chip className={'mr-2 !text-black text-sm'} title={'Manage preferences'} icon={true} src={addFillIcon} />
        </Pressable>
      </div>
      <Spacer></Spacer>
      <Heading1 className={'text-admin-primary my-6'}>Trending</Heading1>
      <div className={'flex justify-center'}>
        <div className={'grid grid-cols-3 gap-12'}>
          {categoryArticles?.count()
            ? categoryArticles
                .toArray()
                .slice(0, 6)
                .map((article: Article) => (
                  <ArticleView
                    key={article.id}
                    size={'md'}
                    image={article.image_url}
                    logo={article.getTags().find((tag) => tag.type === 'publication')?.image_url}
                    timeLapse={formatDistance(new Date((article.date || 1) * 1000), currentDate, { addSuffix: true })}
                    tag={article.getCategoryTags().first()?.tag}
                    headline={article.title?.slice(0, 50).trim()}
                    upvotes={article.upvotes}
                    downvotes={article.downvotes}
                    saved={false}
                  ></ArticleView>
                ))
            : allArticles
                ?.toArray()
                .slice(0, 6)
                .map((article: Article) => (
                  <ArticleView
                    key={article.id}
                    size={'md'}
                    image={article.image_url}
                    logo={article.getTags().find((tag) => tag.type === 'publication')?.image_url}
                    timeLapse={formatDistance(new Date((article.date || 1) * 1000), currentDate, { addSuffix: true })}
                    tag={article.getCategoryTags().first()?.tag}
                    headline={article.title?.slice(0, 50).trim()}
                    upvotes={article.upvotes}
                    downvotes={article.downvotes}
                    saved={false}
                  ></ArticleView>
                ))}
        </div>
      </div>

      <Spacer></Spacer>
      <Heading1 className={'text-admin-primary my-6'}>Trending</Heading1>

      <div className={'flex justify-center pb-12'}>
        <div className={'grid grid-cols-3 gap-16'}>
          {categoryArticles?.count()
            ? categoryArticles
                .toArray()
                .slice(6, 9)
                .map((article: Article) => (
                  <HorizontalArticleView
                    key={article.id}
                    size={'md'}
                    image={article.image_url}
                    logo={article.getTags().find((tag) => tag.type === 'publication')?.image_url}
                    timeLapse={formatDistance(new Date((article.date || 1) * 1000), currentDate, { addSuffix: true })}
                    tag={article.getCategoryTags().first()?.tag}
                    headline={article.title?.slice(0, 50).trim()}
                    upvotes={article.upvotes}
                    downvotes={article.downvotes}
                    saved={false}
                  ></HorizontalArticleView>
                ))
            : allArticles
                ?.toArray()
                .slice(6, 9)
                .map((article: Article) => (
                  <HorizontalArticleView
                    key={article.id}
                    size={'md'}
                    image={article.image_url}
                    logo={article.getTags().find((tag) => tag.type === 'publication')?.image_url}
                    timeLapse={formatDistance(new Date((article.date || 1) * 1000), currentDate, { addSuffix: true })}
                    tag={article.getCategoryTags().first()?.tag}
                    headline={article.title?.slice(0, 50).trim()}
                    upvotes={article.upvotes}
                    downvotes={article.downvotes}
                    saved={false}
                  ></HorizontalArticleView>
                ))}
        </div>
      </div>
    </div>
  );
});

Content.displayName = 'Content';

export default Content;
