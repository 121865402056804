import React, { memo, useEffect } from 'react';
import { listArticlesByCategory } from '@roc-digital/mxm-base/logic';
import { Articles, Article } from '@roc-digital/mxm-base/data';
import { BigArticleView, BodyDark, Button, Heading1, HorizontalArticleView } from '@roc-digital/ui-web';
import { useStateEvent } from '@roc-digital/ui-lib';
import { formatDistance } from 'date-fns';

const Mymxm = memo(() => {
  const [allArticles] = useStateEvent<Articles>('mxm.http.articles', 'listArticlesByCategory.success', undefined);
  const currentDate = new Date();

  useEffect(() => {
    void listArticlesByCategory('politics', 1, 10);
  }, []);

  console.log(allArticles, 'allArticles');

  return (
    <div className={'h-full mt-10 flex flex-col justify-center bg-gray-100 max-w-7xl mx-auto'}>
      <BigArticleView
        key={allArticles?.toArray()[1]?.id}
        size={'md'}
        image={allArticles?.first()?.image_url}
        logo={
          allArticles
            ?.toArray()[1]
            ?.getTags()
            .find((tag) => tag.type === 'publication')?.image_url
        }
        timeLapse={formatDistance(new Date((allArticles?.toArray()[1]?.date || 1) * 1000), currentDate, {
          addSuffix: true,
        })}
        headline={allArticles?.toArray()[1]?.title?.slice(0, 50).trim()}
        upvotes={allArticles?.toArray()[1]?.upvotes}
        downvotes={allArticles?.toArray()[1]?.downvotes}
        saved={false}
      ></BigArticleView>
      <div className={'bg-sky-400 p-10 mt-6 mb-6 flex flex-col'}>
        <BodyDark className={'mb-3'}>Sponsored By Acme group</BodyDark>
        <div className={'flex flex-row justify-between'}>
          <Heading1>Your Ad here</Heading1>
          <Button customClassName={'bg-white border border-slate-200 rounded-none font-bold'}>Learn more</Button>
        </div>
      </div>
      <div className={'flex flex-row w-full'}>
        {allArticles
          ?.toArray()
          .slice(6, 9)
          .map((article: Article) => (
            <HorizontalArticleView
              className={'mr-5 text-black'}
              key={article.id}
              size={'md'}
              image={article.image_url}
              logo={article.getTags().find((tag) => tag.type === 'publication')?.image_url}
              timeLapse={formatDistance(new Date((article.date || 1) * 1000), currentDate, { addSuffix: true })}
              tag={article.getCategoryTags().first()?.tag}
              headline={article.title?.slice(0, 50).trim()}
              upvotes={article.upvotes}
              downvotes={article.downvotes}
              saved={false}
            ></HorizontalArticleView>
          ))}
      </div>
    </div>
  );
});

Mymxm.displayName = 'Mymxm';

export default Mymxm;
