import React from 'react';
import { BodyLight, Button, ButtonSizes, ButtonVariants, Icon, Spacer } from '@roc-digital/ui-web';
import { appleIcon, facebookIcon, googleIcon, twitterIcon } from '@roc-digital/ui-lib';
// import { Event } from '@roc-digital/mxm-base/types';
// import { SessionState } from '@roc-digital/mxm-base/state';
/*import { signinWithApple, signinWithFacebook, signinWithGoogle, signinWithTwitter } from '../../logic';*/

const SocialSignin = () => {
  /*  const navigate = useClickEvent(({ data }: Event<PublisherData>) => {
    const { id } = data;

    switch (id) {
      case 'google':
        signinWithGoogle(SessionState);
        break;
      case 'facebook':
        signinWithFacebook(SessionState);
        break;
      case 'twitter':
        signinWithTwitter(SessionState);
        break;
      case 'apple':
        signinWithApple(SessionState);
        break;
      default:
        break;
    }
  });*/

  return (
    <div className="flex-grow">
      <div className="flex flex-row items-center justify-center">
        <Spacer className="flex-grow" />
        <BodyLight>Sign in with</BodyLight>
        <Spacer className="flex-grow" />
      </div>
      <div className="flex flex-row justify-center items-center my-4">
        <Button
          id="google"
          /*  clickEvent={navigate}*/
          size={ButtonSizes.small}
          variant={ButtonVariants.white}
          customClassName="flex items-center justify-center bg-white rounded-full w-10 h-10 py-2 px-2 mr-4"
        >
          <Icon src={googleIcon.toString()} />
        </Button>
        <Button
          id="facebook"
          /*    clickEvent={navigate}*/
          size={ButtonSizes.small}
          variant={ButtonVariants.white}
          customClassName="flex items-center justify-center bg-white rounded-full w-10 h-10 py-2 px-2 mr-4"
        >
          <Icon src={facebookIcon.toString()} />
        </Button>
        <Button
          id="twitter"
          /* clickEvent={navigate}*/
          size={ButtonSizes.small}
          variant={ButtonVariants.white}
          customClassName="flex items-center justify-center bg-white rounded-full w-10 h-10 py-2 px-2 mr-4"
        >
          <Icon src={twitterIcon.toString()} />
        </Button>
        <Button
          id="apple" /* clickEvent={navigate}*/
          size={ButtonSizes.small}
          variant={ButtonVariants.white}
          customClassName="flex items-center justify-center bg-white rounded-full w-10 h-10 py-2 px-2"
        >
          <Icon src={appleIcon.toString()} />
        </Button>
      </div>
    </div>
  );
};

export default SocialSignin;
