import { Route, Routes, useNavigate, useLocation } from 'react-router';
import { Modal, Notifications, WebMenu } from '@roc-digital/ui-web';

import {
  AuthContext,
  Authentication,
  useCustomEvent,
  useClickEvent,
  PublisherData,
  openModal,
  closeModal,
} from '@roc-digital/ui-lib';
import React, { useEffect, useState } from 'react';
import { Data, Event } from '@roc-digital/mxm-base/types';
import { SessionState } from '@roc-digital/mxm-base/state';
import AppContainer from './elements/AppContainer';
import Content from '@/components/views/Content';
import Mymxm from '@/components/views/Mymxm';
import { signinAnonymouslyWithFirebase, signoutWithFirebase } from '@/logic';
import Login from '@/components/views/Login';

export const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [authState, setAuthState] = useState<Authentication>({
    isAuthenticated: false,
  });
  const [active, setActive] = React.useState<string>('home');
  const [email, setEmail] = React.useState<string>('');

  const handleMenuClick = useClickEvent(({ data }: Event<PublisherData>) => {
    if (data.id === 'login') {
      openModal(<Login />);
    }
    if (data.id === 'signup') {
      openModal(<Login />);
    }
    if (data.id === 'logout') {
      signoutWithFirebase(SessionState);
      setAuthState({ isAuthenticated: false });
      navigate('/');
    }
    navigate(data?.id || ('/' as string));
  });

  useEffect(() => {
    if (!authState.isAuthenticated) {
      signinAnonymouslyWithFirebase(SessionState);
    }
    const path = location.pathname.replace('/', '');

    setActive(path || 'home');
  }, [active, authState.isAuthenticated, location.pathname]);

  useCustomEvent(
    'signin.success',
    ({ data }: Event<Data>) => {
      closeModal();
      navigate('/home');
      setAuthState({ isAuthenticated: true });
      setEmail(data.email);
    },
    { namespace: 'mxm.auth' },
    {},
    [email]
  );

  useCustomEvent(
    'signout',
    async () => {
      closeModal();
      navigate('/home');
      await signinAnonymouslyWithFirebase(SessionState);
      setAuthState({ isAuthenticated: false });
      setEmail('');
    },
    { namespace: 'mxm.auth' },
    {},
    [email]
  );

  return (
    <AuthContext.Provider value={authState}>
      <div className="min-h-screen flex flex flex-col mt-2">
        <div className="flex w-full">
          <div className="h-16 w-full bg-white mb-5 px-4 drop-shadow-md">
            <WebMenu
              clickEvent={handleMenuClick}
              active={active}
              logoUrl={'https://i.imgur.com/Xn1q07t.png'}
              items={[
                { id: 'home', text: 'Home', className: 'text-cyan-400' },
                { id: 'mymxm', text: 'My MxM', className: 'text-cyan-400' },
                ...(email ? [] : [{ id: 'login', text: 'Login', isButton: true }]),
                ...(email ? [] : [{ id: 'signup', text: 'Sign-up', isButton: true }]),
                ...(email ? [{ id: 'logout', text: 'Logout', isButton: true }] : []),
              ]}
            />
          </div>
        </div>
        <AppContainer>
          <Routes>
            <Route path="/home" element={<Content />} />
            <Route path="/" element={<Content />} />
            <Route path="/mymxm" element={<Mymxm />} />
          </Routes>
        </AppContainer>
        <Modal id="mxm.modal" />
        <Notifications />
      </div>
      <Modal id={'mxm.modal'} />
    </AuthContext.Provider>
  );
};
