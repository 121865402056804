// eslint-disable-next-line import/no-cycle

import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, UserCredential, signOut, getIdTokenResult } from 'firebase/auth';
import { User } from '@roc-digital/mxm-base/data';
import { publish } from '@roc-digital/mxm-base/events';
import { SessionState } from '@roc-digital/mxm-base/state';
import { getConfig } from '@roc-digital/mxm-base/config';
import { logError, logInfo } from '@roc-digital/mxm-base/logic';
import { signInAnonymously } from '@firebase/auth';

async function signin(sessionState: typeof SessionState, token: string, id: string, email: string): Promise<User> {
  if (token) {
    await sessionState.setToken(token);
    await sessionState.setLoggedIn(new Date());

    console.log(id, email);
    /*    const user = await readAdminUser(id);*/

    // await SessionState.setUser({
    //   id: id,
    //   email: email,
    // } as User);

    publish('mxm.auth', 'signin.success', { id: id, email: email });

    return {} as User;
  }

  throw new Error('Invalid token');
}

export async function signinAnonymouslyWithFirebase(sessionState: typeof SessionState): Promise<User> {
  if (getConfig().mocking) {
    return signin(sessionState, 'mock', '1', 'anonymous');
  }

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  return signInAnonymously(auth)
    .then(async (credentials: UserCredential) =>
      signin(sessionState, await credentials.user?.getIdToken(), credentials.user.uid, credentials.user.email || '')
    )
    .catch((error) => {
      publish('mxm.auth', 'signin.failed', { error: new Error('Invalid username or password') });
      publish('mxm.auth', 'signout');
      throw error;
    });
}

export async function signinWithFirebase(
  sessionState: typeof SessionState,
  username: string,
  password: string
): Promise<User> {
  if (getConfig().mocking) {
    return signin(sessionState, 'mock', '1', username);
  }

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  return signInWithEmailAndPassword(auth, username, password)
    .then(async (credentials: UserCredential) => {
      //console.log('credentials', await credentials.user?.getIdToken());
      return signin(
        sessionState,
        await credentials.user?.getIdToken(),
        credentials.user.uid,
        credentials.user.email || ''
      );
    })
    .catch((error) => {
      publish('mxm.auth', 'signin.failed', { error: new Error('Invalid username or password') });
      publish('mxm.auth', 'signout');
      throw error;
    });
}

export function signoutWithFirebase(sessionState: typeof SessionState): Promise<void> {
  if (getConfig().mocking) {
    sessionState.clear();
    publish('mxm.auth', 'signout');
    return Promise.resolve();
  }
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  return signOut(auth)
    .then(() => {
      sessionState.clear();
      publish('mxm.auth', 'signout');
    })
    .catch((error) => {
      sessionState.clear();
      publish('mxm.auth', 'signout');
      logError('signoutWithFirebase', error);
    });
}

export async function refreshTokenIfExpired(sessionState: typeof SessionState): Promise<string> {
  logInfo('[firebase.refreshTokenIfExpired]');
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  if (getConfig().mocking) {
    return '';
  }

  if (!auth.currentUser) {
    return '';
  }

  const fbtoken = await getIdTokenResult(auth.currentUser);

  if (!fbtoken) {
    return '';
  }

  const currentToken = await sessionState.getToken();

  if (currentToken !== fbtoken.token) {
    logInfo('[firebase.refreshTokenIfExpired] Token has changed, updating');
    await sessionState.setToken(fbtoken.token);
    return fbtoken.token;
  }

  const expTime = new Date(fbtoken.expirationTime).getTime();

  if (expTime < Date.now() - 600) {
    logInfo('[firebase.refreshTokenIfExpired] Refreshing token');
    const fbtoken = await getIdTokenResult(auth.currentUser);

    await sessionState.setToken(fbtoken.token);
    return fbtoken.token;
  }

  return '';
}
